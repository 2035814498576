<template>
    <div class="flex w-full py-4">
        <div class="max-w-[820px] w-full mx-auto bg-gray-50 p-4 rounded-md shadow-lg">
            <div class="flex w-full">
                <Logo class="mb-4"></Logo>
                <button @click="addNewGroup()" class="bg-green-500 text-white rounded ml-auto px-2 py-1 my-auto">Nieuwe groep</button>
            </div>

            <div class="mt-4 flex">
                <div class="flex flex-col flex-grow pr-1">
                    <p class="text-sm">Naam:</p>
                    <input type="text" v-model="name" placeholder="Naam" class="flex-grow border border-gray-200 rounded p-1">
                </div>
                <div class="flex flex-col flex-grow pr-1">
                    <p class="text-sm">Locatie:</p>
                    <select v-model="location" class="flex-grow border border-gray-200 rounded p-1">
                        <option value="Thuis 🏠">🏠 Thuis</option>
                        <option value="Kantoor 🔥">🔥 Kantoor</option>
                        <option value="Thuis & Kantoor 🏘️">🏘️ Thuis & Kantoor</option>
                    </select>
                </div>
                <div class="flex flex-col w-36">
                    <p class="text-sm">Type daily goal:</p>
                    <select
                            v-model="isEndOfDay"
                            class="flex-grow border border-gray-200 rounded px-2 py-1 text-center"
                            :class="{
                                'bg-lightblue-800 text-lightblue-100': isEndOfDay,
                                'bg-yellow-200 text-yellow-800': !isEndOfDay
                            }"
                    >
                        <option :value="false">🌞 Opstart</option>
                        <option :value="true">🌚 Afsluiting</option>
                    </select>
                </div>
            </div>

            <p class="text-sm mt-4">Goals:</p>
            <div
                    v-for="(group, groupIndex) in groups"
                    :key="groupIndex"
                    class="flex flex-col w-full"
            >
                <div class="flex flex-col w-full">
                    <p class="text-sm">Groep naam:</p>

                    <div class="flex flex-shrink-0">
                        <input type="text" v-model="groups[groupIndex].title" placeholder="Groep naam" class="flex-grow border border-gray-200 rounded p-1">

                        <div class="flex flex-col ml-2">
                            <select
                                    v-model="groups[groupIndex].visible"
                                    class="flex-grow px-2 border border-gray-200 rounded"
                                    :class="{
                                    'bg-green-100 text-green-800': groups[groupIndex].visible,
                                    'bg-gray-100 text-gray-500': !groups[groupIndex].visible
                                }"
                            >
                                <option :value="true">👁‍ Visible</option>
                                <option :value="false">👁‍🗨 Hidden</option>
                            </select>
                        </div>

                        <button @click="addNewGroupGoal(groupIndex)" class="bg-green-500 text-white rounded ml-2 px-2 py-1">Nieuw goal</button>
                        <button @click="removeGroup(groupIndex)" class="bg-red-500 text-white rounded ml-2 px-2 py-1">X</button>
                    </div>
                </div>
                <div class="pl-4">
                    <GoalRow
                            v-for="(goal, goalIndex) in group.goals"
                            :key="goalIndex"
                            :goal-initial="goal"
                            @new-goal="updateGoal($event, groupIndex, goalIndex)"
                            @delete-goal="removeGoal(groupIndex, goalIndex)"
                    ></GoalRow>
                </div>
            </div>

            <div class="flex w-full mt-4">
                <p class="text-sm">Resultaat:</p>
                <p @click="copyDailyGoals" class="text-sm ml-auto text-lightblue-600 cursor-pointer">Kopiëren</p>
            </div>
            <div class="w-full bg-[#FFF] border border-gray-200 p-1 rounded-md" v-html="messageResultHtml"></div>
            <div ref="dailyGoalsOutput" class="opacity-0 h-px" v-html="messageResultSlackMessage"></div>
        </div>
    </div>
</template>

<script>
import Logo from './components/Logo';
import GoalRow from './components/GoalRow';

export default {
    name: 'App',
    components: {
        Logo,
        GoalRow
    },
    data() {
        return {
            name: "",
            location: "Kantoor",
            isEndOfDay: false,
            dateFormatted: "",
            groups: [],
            goalStatusEmoji: {
                TODO: "❌",
                IN_PROGRESS: "🟨",
                FINISHED: "✅"
            },

            clickupBaseUrl: "https://app.clickup.com/t/24474727/",
            clickupTicketKeyRegex: /^([a-zA-Z0-9]{1,10}-[0-9]{1,10})/
        }
    },
    methods: {
        saveChanges() {
            window.localStorage.setItem("groups", JSON.stringify({ groups: this.groups }));
            window.localStorage.setItem("name", this.name);
            window.localStorage.setItem("location", this.location);
            window.localStorage.setItem("isEndOfDay", this.isEndOfDay);
        },

        getGroups(){
            return JSON.parse(window.localStorage.getItem("groups"))?.groups ?? [];
        },
        addNewGroup() {
            this.groups.push({
                title: "",
                visible: true,
                goals: []
            });
        },
        addNewGroupGoal(groupIndex) {
            this.groups[groupIndex].goals.push({
                title: "",
                status: "TODO"
            });
        },
        updateGoal(data, groupIndex, goalIndex) {
            this.groups[groupIndex].goals[goalIndex] = data;
        },
        removeGoal(groupIndex, goalIndex) {
            this.groups[groupIndex].goals.splice(goalIndex, 1);
        },
        removeGroup(groupIndex) {
            const group = this.groups[groupIndex];

            if (group.goals.length === 0 || ! group.goals.some((goal) => goal.status !== "FINISHED")) {
                this.groups.splice(groupIndex, 1);

                return;
            }

            if (! confirm(`De groep "${group.title}" heeft nog steeds goals die niet klaar zijn!\nWeet je zeker dat je deze groep wil verwijderen?`)) {
                // Aborted by user

                return;
            }

            this.groups.splice(groupIndex, 1);
        },
        updateDateFormatted() {
            const currentDate = new Date();
            const monthNames = ["Januari", "Februari", "Maart", "April", "Mei", "Juni", "Juli", "Augustus", "September", "Oktober", "November", "December"];

            this.dateFormatted = `${currentDate.getDate()} ${monthNames[currentDate.getMonth()]} ${currentDate.getFullYear()}`;
        },
        copyDailyGoals() {
            window.getSelection().removeAllRanges();

            let range = document.createRange();
            range.selectNode(this.$refs.dailyGoalsOutput);
            window.getSelection().addRange(range);
            document.execCommand('copy');
            window.getSelection().removeAllRanges();
        }
    },
    computed: {
        messageResultSlackMessage() {
            if (! this.name) {
                return null;
            }

            let message = `${this.name} ${this.dateFormatted} - ${this.isEndOfDay ? 'Afsluiting' : 'Opstart'}<br/>Locatie: ${this.location}<br/><br/>`;

            this.groups.filter((goal) => goal.visible).forEach((group) => {
                let goalBuilder = '';

                group.goals.filter((goal) => goal.status !== 'HIDDEN').forEach((goal) => {
                    const issueKey = this.clickupTicketKeyRegex.exec(goal.title);
                    let statusIcon = "-";

                    if (this.isEndOfDay) {
                        statusIcon = this.goalStatusEmoji[goal.status];
                    }

                    if (issueKey) {
                        const clickupLink = `${this.clickupBaseUrl}${issueKey[0]}`;

                        // Detected a clickup ticket, create link as well
                        goalBuilder += `&nbsp;&nbsp;${statusIcon} ${goal.title} _(ClickUp: [${clickupLink}](${clickupLink}))_<br/>`;
                    } else {
                        goalBuilder += `&nbsp;&nbsp;${statusIcon} ${goal.title}<br/>`;
                    }
                });

                message += `*${group.title}*<br/>${goalBuilder}<br/>`;
            });

            return message;
        },
        messageResultHtml() {
            if (! this.name) {
                return '<p class="text-red-500">Vul een naam in</p>';
            }

            let message = `${this.name} ${this.dateFormatted} - ${this.isEndOfDay ? 'Afsluiting' : 'Opstart'}<br/>Locatie: ${this.location}<br/><br/>`;

            this.groups.filter((goal) => goal.visible).forEach((group) => {
                let goalBuilder = '';

                group.goals.filter((goal) => goal.status !== 'HIDDEN').forEach((goal) => {
                    const issueKey = this.clickupTicketKeyRegex.exec(goal.title);
                    let statusIcon = "-";

                    if (this.isEndOfDay) {
                        statusIcon = this.goalStatusEmoji[goal.status];
                    }

                    if (issueKey) {
                        // Detected a clickup ticket, create link as well
                        goalBuilder += `&nbsp;&nbsp;${statusIcon} ${goal.title} <span class="italic">(ClickUp: <a href="${this.clickupBaseUrl}${issueKey[0]}" target="_blank" class="text-lightblue-600 underline">${this.clickupBaseUrl}${issueKey[0]}</a>)</span><br/>`;
                    } else {
                        goalBuilder += `&nbsp;&nbsp;${statusIcon} ${goal.title}<br/>`;
                    }
                });

                message += `<b>${group.title}</b><br/>${goalBuilder}<br/>`;
            });

            return message;
        }
    },
    watch: {
        name: function() {
            this.saveChanges();
        },
        isEndOfDay: function() {
            this.saveChanges();
        },
        location: function() {
            this.saveChanges();
        },
        groups: {
            deep: true,
            handler: function () {
                this.saveChanges();
            }
        },
    },
    mounted() {
        this.updateDateFormatted();
        this.name       = window.localStorage.getItem("name");
        this.location   = window.localStorage.getItem("location")  ?? "Kantoor";
        this.isEndOfDay = window.localStorage.getItem("isEndOfDay") === "true";
        this.groups     = this.getGroups();

        setInterval(() => this.updateDateFormatted(), 60000);
    }
};
</script>
