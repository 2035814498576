<template>
    <div v-if="goal" class="w-full flex border-b border-gray-300 p-2">
        <input type="text" v-model="goal.title" placeholder="Wat was je goal?" class="flex-grow border border-gray-200 rounded p-1">
        <div class="flex-shrink-0 flex ml-2">
            <select
                    v-model="goal.status"
                    class="flex-grow border border-gray-200 rounded p-1"
                    :class="{
                        'bg-red-50 text-red-800': goal.status === 'TODO',
                        'bg-yellow-200 text-yellow-800': goal.status === 'IN_PROGRESS',
                        'bg-green-100 text-green-800': goal.status === 'FINISHED',
                        'bg-gray-100 text-gray-500': goal.status === 'HIDDEN'
                    }"
            >
                <option value="TODO">❌ Todo</option>
                <option value="IN_PROGRESS">🟨 In progress</option>
                <option value="FINISHED">✅ Finished</option>
                <option value="HIDDEN">👁‍🗨 Hidden</option>
            </select>
        </div>
        <div class="flex-shrink-0 flex ml-2">
            <span @click="$emit('delete-goal')" class="flex-shrink-0 cursor-pointer text-red-500 my-auto ml-1">X</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GoalRow',
    props: {
        goalInitial: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            goal: null
        }
    },
    mounted() {
        this.goal = this.goalInitial;
    },
    watch: {
        goal: {
            deep: true,

            handler(value) {
                this.$emit('new-goal', value);
            }
        },
        goalInitial: {
            deep: true,

            handler(value) {
                this.goal = value;
            }
        },
    }
};
</script>
