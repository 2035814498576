<template>
    <svg
            viewBox="0 0 168 43"
            class="h-8 w-auto"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
    >
        <path d="M12.1182 0C13.6106 0 14.8386 0.127919 15.8108 0.375228C16.7829 0.622538 17.6102 0.886903 18.2839 1.17685L16.9023 7.39371C16.331 7.1464 15.7084 6.96731 15.0177 6.86497C14.3269 6.76264 13.6276 6.70294 12.9198 6.70294C11.9647 6.70294 11.1545 6.83939 10.4979 7.10376C9.84122 7.36812 9.32954 7.73482 8.95432 8.19533C8.57909 8.65584 8.31472 9.20162 8.16122 9.84122C7.99919 10.4808 7.92244 11.1716 7.92244 11.9135V13.295H17.704V19.8871H7.92244V41.2581H0V11.8026C0 8.1868 1.01482 5.31289 3.05299 3.18944C5.09117 1.06599 8.11858 0 12.1182 0ZM33.4891 41.7868C31.1866 41.7527 29.319 41.5054 27.8778 41.0449C26.4451 40.5844 25.3109 39.9362 24.4751 39.1005C23.6394 38.2648 23.0765 37.2585 22.7781 36.0731C22.4796 34.8877 22.3261 33.5488 22.3261 32.0564V1.27919L30.2485 0V30.4617C30.2485 31.1695 30.2997 31.8091 30.4106 32.372C30.5214 32.9348 30.7176 33.4209 31.0246 33.8047C31.323 34.197 31.758 34.5125 32.3293 34.7598C32.8922 35.0071 33.6597 35.1691 34.6148 35.2374L33.4891 41.7868Z"/>
        <path
                d="M63.9508 27.2211C63.9508 29.4213 63.6353 31.4339 62.9957 33.2589C62.3561 35.0839 61.4351 36.6445 60.2327 37.9407C59.0302 39.237 57.5805 40.2347 55.9005 40.9425C54.2205 41.6504 52.3273 42.0085 50.2379 42.0085C48.1827 42.0085 46.3151 41.6504 44.6266 40.9425C42.9466 40.2347 41.4968 39.237 40.2944 37.9407C39.0919 36.6445 38.1453 35.0924 37.4802 33.2589C36.8065 31.4339 36.4739 29.4213 36.4739 27.2211C36.4739 25.0209 36.8235 23.0254 37.5143 21.2175C38.205 19.4095 39.1602 17.866 40.3882 16.5953C41.6162 15.3247 43.066 14.3269 44.746 13.6191C46.426 12.9113 48.2595 12.5531 50.2465 12.5531C52.2676 12.5531 54.1181 12.9113 55.7981 13.6191C57.4781 14.3269 58.9279 15.3161 60.1303 16.5953C61.3328 17.8745 62.2708 19.4095 62.9445 21.2175C63.6097 23.0169 63.9508 25.0209 63.9508 27.2211ZM55.8664 27.2211C55.8664 24.7736 55.3803 22.8548 54.4081 21.4562C53.4359 20.0577 52.0459 19.3584 50.2379 19.3584C48.43 19.3584 47.0314 20.0577 46.0422 21.4562C45.053 22.8548 44.5498 24.7821 44.5498 27.2211C44.5498 29.6686 45.0444 31.6045 46.0422 33.0457C47.0314 34.4784 48.4385 35.1947 50.2379 35.1947C52.0459 35.1947 53.4359 34.4784 54.4081 33.0457C55.3803 31.6045 55.8664 29.6601 55.8664 27.2211Z"
        />
        <path
                d="M94.8902 27.2211C94.8902 29.4213 94.5746 31.4339 93.935 33.2589C93.2954 35.0839 92.3744 36.6445 91.172 37.9407C89.9696 39.237 88.5198 40.2347 86.8398 40.9425C85.1598 41.6504 83.2666 42.0085 81.1773 42.0085C79.122 42.0085 77.2544 41.6504 75.5659 40.9425C73.8859 40.2347 72.4361 39.237 71.2337 37.9407C70.0313 36.6445 69.0847 35.0924 68.4195 33.2589C67.7458 31.4339 67.4132 29.4213 67.4132 27.2211C67.4132 25.0209 67.7628 23.0254 68.4536 21.2175C69.1444 19.4095 70.0995 17.866 71.3275 16.5953C72.547 15.3161 74.0053 14.3269 75.6853 13.6191C77.3653 12.9113 79.1988 12.5531 81.1858 12.5531C83.2069 12.5531 85.0575 12.9113 86.7375 13.6191C88.4175 14.3269 89.8672 15.3161 91.0697 16.5953C92.2721 17.8745 93.2102 19.4095 93.8839 21.2175C94.549 23.0169 94.8902 25.0209 94.8902 27.2211ZM86.8057 27.2211C86.8057 24.7736 86.3196 22.8548 85.3474 21.4562C84.3752 20.0577 82.9852 19.3584 81.1773 19.3584C79.3694 19.3584 77.9708 20.0577 76.9815 21.4562C75.9923 22.8548 75.4891 24.7821 75.4891 27.2211C75.4891 29.6686 75.9838 31.6045 76.9815 33.0457C77.9708 34.4784 79.3779 35.1947 81.1773 35.1947C82.9852 35.1947 84.3752 34.4784 85.3474 33.0457C86.3196 31.6045 86.8057 29.6601 86.8057 27.2211Z"
        />
        <path d="M116.525 20.305C115.818 20.1259 114.982 19.9383 114.027 19.7507C113.072 19.5545 112.04 19.4607 110.948 19.4607C110.454 19.4607 109.857 19.5034 109.166 19.5972C108.475 19.6824 107.955 19.7848 107.597 19.8871V41.2581H99.6743V14.7789C101.09 14.2843 102.77 13.8152 104.697 13.3718C106.625 12.9283 108.782 12.7066 111.153 12.7066C111.579 12.7066 112.091 12.7322 112.696 12.7834C113.302 12.8345 113.899 12.9113 114.504 12.9966C115.11 13.0818 115.707 13.1927 116.312 13.3121C116.918 13.44 117.429 13.585 117.856 13.7641L116.525 20.305Z"/>
        <path d="M129.445 5.21056C129.445 6.66031 128.976 7.80305 128.038 8.63879C127.1 9.47452 125.991 9.88386 124.712 9.88386C123.433 9.88386 122.324 9.46599 121.386 8.63879C120.448 7.80305 119.979 6.66031 119.979 5.21056C119.979 3.76082 120.448 2.61807 121.386 1.78234C122.324 0.946602 123.433 0.537262 124.712 0.537262C125.991 0.537262 127.1 0.95513 128.038 1.78234C128.976 2.61807 129.445 3.76082 129.445 5.21056ZM128.695 41.2495H120.772V13.2865H128.695V41.2495Z"/>
        <path
                d="M142.834 35.5614C144.284 35.5614 145.316 35.4165 145.921 35.135C146.527 34.8536 146.825 34.2993 146.825 33.4891C146.825 32.8495 146.433 32.2952 145.657 31.8177C144.881 31.3401 143.687 30.8028 142.092 30.1974C140.856 29.7369 139.73 29.2593 138.715 28.7647C137.709 28.2701 136.848 27.6731 136.14 26.9823C135.432 26.2916 134.886 25.4644 134.494 24.5092C134.102 23.5541 133.905 22.4028 133.905 21.0554C133.905 18.4374 134.878 16.3566 136.83 14.8386C138.783 13.3121 141.453 12.5531 144.855 12.5531C146.552 12.5531 148.19 12.7066 149.742 13.0051C151.302 13.3036 152.539 13.6361 153.46 13.9858L152.078 20.1515C151.157 19.8359 150.16 19.546 149.077 19.2987C147.994 19.0514 146.783 18.9235 145.435 18.9235C142.953 18.9235 141.717 19.6142 141.717 20.9957C141.717 21.3113 141.768 21.6012 141.879 21.8485C141.99 22.0958 142.195 22.3346 142.519 22.5649C142.834 22.7951 143.269 23.0424 143.823 23.3153C144.369 23.5797 145.077 23.8782 145.921 24.2022C147.652 24.8418 149.094 25.4814 150.228 26.104C151.362 26.7265 152.257 27.4087 152.914 28.1251C153.571 28.85 154.031 29.6516 154.296 30.5385C154.56 31.4254 154.696 32.4487 154.696 33.6171C154.696 36.3801 153.656 38.4609 151.584 39.8766C149.511 41.2922 146.578 42 142.783 42C140.301 42 138.238 41.7868 136.592 41.3604C134.946 40.934 133.803 40.5844 133.163 40.2944L134.494 33.8644C135.841 34.3931 137.223 34.811 138.638 35.118C140.054 35.4165 141.453 35.5614 142.834 35.5614Z"
        />
        <path d="M167.028 37.0538C167.028 38.6485 166.525 39.8595 165.51 40.6952C164.504 41.531 163.361 41.9403 162.082 41.9403C160.803 41.9403 159.66 41.5224 158.653 40.6952C157.647 39.8595 157.135 38.6485 157.135 37.0538C157.135 35.4591 157.639 34.2481 158.653 33.4124C159.66 32.5767 160.803 32.1588 162.082 32.1588C163.361 32.1588 164.504 32.5767 165.51 33.4124C166.516 34.2396 167.028 35.4591 167.028 37.0538Z"/>
    </svg>
</template>
<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Logo",
};
</script>